










































































































import { Component, Emit, Prop, Watch } from "vue-property-decorator";
import UserRolesMixin from "@/mixins/UserRoles.vue";
import PDFDocumentWrapper from "./PDFDocumentWrapper.vue";
import { getModule } from "vuex-module-decorators";
import DocViewerStore from "@/store/doc-viewer.store";
import { randomNumberGenerator } from "@/helpers/numbers-helpers";
import { PDFPage, PDFDocument } from "@/models/document-entry/pdf-page";
import { cloneDeep } from "lodash";
import SimpleConfirmationModal from "@/components/design-system/modals/SimpleConfirmationModal.vue";
import IconTextButton from "@/components/design-system/buttons/IconTextButton.vue";
import { documentSplitterService } from "@/services/document-splitter.service";
import { reportErrorCaptured } from "@/helpers/error-captured";
import Catch from "@/shared/decorators/catch-errors";
import options from "@/shared/constants/toast-options";
import { checkCurrentRouteAndRedirect } from "@/helpers/router-helpers";

const pdfjs = require("pdfjs-dist");

@Component({
  components: {
    "pdf-document-wrapper": PDFDocumentWrapper,
    "simple-confirmation-modal": SimpleConfirmationModal,
    "icon-text-button": IconTextButton
  }
})
export default class PDFSplitter extends UserRolesMixin {
  @Prop() private visible?: boolean;
  @Prop() private invoiceIsValidationServices!: boolean;

  // REACTIVE PROPERTIES
  private contentTopMargin = 0;
  private readonly docViewerStore: DocViewerStore = getModule(
    DocViewerStore,
    this.$store
  );
  private pdfPages: PDFPage[] = [];
  private pdfDocuments: PDFDocument[] = [];
  private targetPDFPage: PDFPage | null = null;
  private targetPDFPages: PDFPage[] = [];
  private targetPDFDocument: PDFDocument | null = null;
  private documentsContainerKey = randomNumberGenerator();
  private isDeleteButtonEnabled = false;
  private displayDeleteModal = false;
  private selectionCount = 0;
  private displayExitWithoutSavingModal = false;
  private initialPages: string[] = [];
  private siblingPDFPageKey = 0;
  private position = "";
  private areSelectedPagesBeingDragged = false;
  private isActionButtonDisabled = false;

  // METHODS

  generateCanvasesFromPDFFile(pdfUrlFromBlob: string) {
    const loadingTask = pdfjs.getDocument(pdfUrlFromBlob);
    const result: PDFPage[] = [];

    return loadingTask.promise.then(
      (pdf: any) => {
        const totalPages = pdf.numPages;
        for (let pageNumber = 1; pageNumber < totalPages + 1; pageNumber++) {
          pdf.getPage(pageNumber).then(
            (page: any) => {
              const scale = 0.3;
              const viewport = page.getViewport({ scale: scale });

              const canvas = document.createElement("canvas");

              // Prepare canvas using PDF page dimensions
              const context = canvas.getContext("2d");
              canvas.height = viewport.height;
              canvas.width = viewport.width;

              // Render PDF page into canvas context
              const renderContext = {
                canvasContext: context,
                viewport: viewport
              };

              const renderTask = page.render(renderContext);
              renderTask.promise.then(() => {
                result[pageNumber - 1] = {
                  canvas: canvas,
                  pageNumber: pageNumber,
                  key: randomNumberGenerator(),
                  selected: false
                };
                if (result.length === totalPages) {
                  this.docViewerStore.setPdfCanvases(result);
                  this.pdfPages = result;
                }
              });
            },
            (reason: any) => {
              // PDF render error
              console.error(reason);
            }
          );
        }
      },
      (reason: any) => {
        // PDF loading error
        console.error(reason);
      }
    );
  }

  // loads the page being dragged
  loadTargetPage(pageKey: number) {
    this.targetPDFPage = this.pdfPages.filter(page => page.key == pageKey)[0];
  }

  // loads the selected pages
  loadSelectedPages(pageKey: number) {
    this.loadTargetPage(pageKey);
    //select the page that is being dragged
    const draggedPage = this.getPageByKey(pageKey);
    if (draggedPage) draggedPage.selected = true;
    //set target pages to all currently selected pages
    this.targetPDFPages = this.selectedPages;
    this.areSelectedPagesBeingDragged = true;
  }

  // returns array of selected pages
  get selectedPages() {
    const selectedPages: PDFPage[] = [];
    this.pdfDocuments.forEach(doc => {
      doc.pages.forEach(page => {
        if (page.selected) {
          selectedPages.push(page);
        }
      });
    });
    return selectedPages;
  }

  resetTargetPDFPage() {
    this.targetPDFPage = null;
  }

  resetTargetPDFPages() {
    this.targetPDFPages = [];
  }

  loadTargetDocument(value: {
    docKey: number;
    siblingPDFPageKey: number;
    position: string;
  }) {
    this.siblingPDFPageKey = value.siblingPDFPageKey;
    this.targetPDFDocument = this.pdfDocuments.filter(
      doc => doc.key == value.docKey
    )[0];
    this.position = value.position;
  }

  resetTargetPDFDocument() {
    this.targetPDFDocument = null;
  }

  removePDFPagesFromPDFDocumentsByRepeatedKeys() {
    this.targetPDFPages.forEach(targetPage => {
      this.pdfDocuments.forEach(doc => {
        doc.pages = doc.pages.filter(page => page.key != targetPage.key);
      });
    });
  }

  removePDFPageFromPDFDocumentBySelection() {
    this.pdfDocuments.forEach(doc => {
      doc.pages = doc.pages.filter(page => !page.selected);
    });
  }

  deselectAllPages() {
    this.areSelectedPagesBeingDragged = false;
    this.selectedPages.forEach(page => {
      page.selected = false;
    });
  }

  cleanPDFDocument() {
    this.pdfDocuments = this.pdfDocuments.filter(doc => doc.pages.length > 0);
  }

  createNewPDFDocument() {
    this.removePDFPagesFromPDFDocumentsByRepeatedKeys();
    if (this.targetPDFPages) {
      this.pdfDocuments.push({
        key: randomNumberGenerator(),
        pages: cloneDeep(this.targetPDFPages)
      });
    }
  }

  reorderPagePosition() {
    if (this.targetPDFDocument && this.targetPDFPage) {
      // find sibling pdfPage index
      const siblingIndex = this.targetPDFDocument.pages.findIndex(page => {
        return page.key == this.siblingPDFPageKey;
      });
      // find dragging pdfPage index
      const targetIndex = this.targetPDFDocument.pages.findIndex(page => {
        return page.key == this.targetPDFPage?.key;
      });
      this.removePDFPagesFromPDFDocumentsByRepeatedKeys();
      let index = siblingIndex;
      const indexDiff = siblingIndex - targetIndex;
      const notDroppedInDifferentDocument =
        siblingIndex != 0 && targetIndex != -1;
      //Leave page in same place if it's dropped between itself and next page
      if (
        indexDiff == 1 &&
        this.position == "between" &&
        notDroppedInDifferentDocument
      ) {
        index = targetIndex;
      }
      //Place page betwen pages if it's dropped forward and betwen two sibling pages.
      if (
        indexDiff > 1 &&
        this.position == "between" &&
        notDroppedInDifferentDocument
      ) {
        index = index - 1;
      }
      //Drop page in position if index is positive, else append to document
      if (index != -1) {
        this.targetPDFPages.forEach(page => {
          this.targetPDFDocument?.pages.splice(index, 0, page);
          index++;
        });
      } else {
        this.targetPDFPages.forEach(page => {
          this.targetPDFDocument?.pages.push(page);
        });
      }
    }
  }

  managePDFPageDrop() {
    // If page is dropped out documents wrapper, create new document
    if (!this.targetPDFDocument) {
      this.createNewPDFDocument();
    } else {
      this.reorderPagePosition();
    }
    this.resetTargets();
  }

  resetTargets() {
    this.cleanPDFDocument();
    this.resetTargetPDFPage();
    this.resetTargetPDFDocument();
    this.deselectAllPages();
    this.resetTargetPDFPages();
    this.forceDocumentsContainerRerender();
  }

  forceDocumentsContainerRerender() {
    this.documentsContainerKey = randomNumberGenerator();
  }

  // returns page that has given key
  private getPageByKey(pageKey: number): PDFPage | null {
    let result: PDFPage | null = null;
    this.pdfDocuments.forEach(doc => {
      doc.pages.forEach(page => {
        if (page.key == pageKey) {
          result = page;
        }
      });
    });
    return result;
  }

  // modifies the selected status of page
  handlePageSelect(pageKey: number) {
    let areAnySelected = false;
    this.pdfDocuments.forEach(doc => {
      doc.pages.forEach(page => {
        if (page.key == pageKey) {
          page.selected = !page.selected;
        }
        areAnySelected = areAnySelected || page.selected;
      });
    });
    this.isDeleteButtonEnabled = areAnySelected;
    this.forceDocumentsContainerRerender();
  }

  // generate delete modal text
  modalText() {
    const docsPages: Map<number, number[]> = new Map();
    let selectionCount = 0;
    this.pdfDocuments.forEach((doc, docIndex) => {
      doc.pages.forEach((page, pageIndex) => {
        const docNumber = docIndex + 1;
        if (page.selected) {
          selectionCount += 1;
          const pageNumber = pageIndex + 1;
          if (docsPages.has(docNumber)) {
            const docA = docsPages.get(docNumber);
            docA?.push(pageNumber);
          } else {
            docsPages.set(docNumber, [pageNumber]);
          }
        }
      });
    });
    let text = "Are you sure you want to delete the following?\n";
    docsPages.forEach((docValue, docKey) => {
      const sortedDoc = docValue.sort((a, b) => a - b);
      sortedDoc.forEach((page, pageIndex) => {
        const lastPageOfCurrentDoc = pageIndex + 1 == docValue.length;
        if (pageIndex == 0) {
          text += `Page ${page}`;
          text += lastPageOfCurrentDoc ? ` of Document ${docKey}\n` : "";
        } else if (lastPageOfCurrentDoc) {
          text += ` and Page ${page} of Document ${docKey}\n`;
        } else {
          text += `, Page ${page}`;
        }
      });
    });
    this.selectionCount = selectionCount;
    return text;
  }

  private pagesPerDoc(): string[] {
    let content: string[] = [];
    const regex = /[\][]/g;
    this.pdfDocuments.forEach(document => {
      let pagesArr: number[] = [];
      document.pages.forEach(page => {
        pagesArr.push(page.pageNumber);
      });
      pagesArr = pagesArr.sort((a, b) => a - b);
      content.push(JSON.stringify(pagesArr).replace(regex, ""));
    });
    content = content.filter(el => el != "").sort();
    return content;
  }

  @Catch(reportErrorCaptured)
  async handleDocumentSplitSaveChanges() {
    this.isActionButtonDisabled = true;
    const response = await documentSplitterService.splitDocument(
      parseInt(this.$route.params.id),
      this.pagesPerDoc()
    );
    this.navigateToDocumentList();
    if (response.status == 0 || response.status == 200) {
      this.handleSuccessMessage();
    }
    this.isActionButtonDisabled = false;
  }

  navigateToDocumentList() {
    if (this.invoiceIsValidationServices) {
      // if Validation Services is processing the document, go back to VS invoice list for selected customer
      checkCurrentRouteAndRedirect(
        this.$router,
        "/validation-services/" + this.userStore.getSelectedCustomer.id
      );
    } else {
      // otherwise go back to customers' invoice list
      checkCurrentRouteAndRedirect(this.$router, "/documents");
    }
  }

  handleSuccessMessage() {
    this.$toasted.show(
      "<p>Documents split successfully and are being reprocessed.</p>",
      options.SUCCESS_OPTIONS
    );
  }

  handleDeleteModal() {
    if (this.isDeleteButtonEnabled) {
      this.displayDeleteModal = !this.displayDeleteModal;
    }
  }

  handleDeletePages() {
    this.removePDFPageFromPDFDocumentBySelection();
    this.selectionCount = 0;
    this.handleDeleteModal();
    this.isDeleteButtonEnabled = false;
  }

  handleExitWithoutSavingModal() {
    if (this.initialPages.join("-") != this.pagesPerDoc().join("-")) {
      this.displayExitWithoutSavingModal = !this.displayExitWithoutSavingModal;
    } else {
      this.handleNotSaved();
    }
  }

  handleNotSaved() {
    this.displayExitWithoutSavingModal = false;
    this.resetDocumentSplitValues();
    this.closeSheet();
    this.pdfPages = this.docViewerStore.getPdfCanvases;
  }

  // DRAG&DROP HANDLERS
  handleDragOver(domEvent: any) {
    domEvent.preventDefault();
  }

  handleDropPage(domEvent: any) {
    domEvent.preventDefault();
    this.managePDFPageDrop();
  }

  // WATCHERS METHODS
  @Watch("pdfPages")
  loadPages() {
    this.pdfDocuments.push({
      key: randomNumberGenerator(),
      pages: this.pdfPages
    });
    setTimeout(() => {
      this.initialPages = this.pagesPerDoc();
    }, 200);
  }

  // EMIT METHODS
  @Emit("handle-splitter") closeSheet() {}

  // LIFECYCLE METHODS
  created() {
    const header = document.getElementById("doc-split-header");
    this.contentTopMargin = header?.clientHeight ? header?.clientHeight : 170;

    const pdfDocument = this.docViewerStore.getPdfDocument;
    if (pdfDocument) {
      this.generateCanvasesFromPDFFile(pdfDocument);
    }
  }

  updated() {
    this.$nextTick(function() {
      const docWrapperDropzone = document.getElementById(
        "documents-wrapper-dropzone"
      );
      if (docWrapperDropzone) {
        docWrapperDropzone.addEventListener("dragover", this.handleDragOver);
        docWrapperDropzone.addEventListener("drop", this.handleDropPage);
      }
    });
  }

  resetDocumentSplitValues() {
    this.pdfDocuments = [];
    this.loadPages();
    this.resetTargets();
  }
}
