




































import { Component, Prop, Vue, Watch, Ref } from "vue-property-decorator";
import range from "lodash/range";
import PDFWebHeader from "./PDFWebHeader.vue";
import PDFWebPage from "./PDFWebPage.vue";
import PDFSplitter from "./PDFSplitter.vue";
import { getModule } from "vuex-module-decorators";
import DocViewerStore from "@/store/doc-viewer.store";
import _ from "lodash";

/**
 * Class Specific Interface
 */
interface ViewportScrollData {
  scrollLeft: number;
  scrollTop: number;
  scrollHeight: number;
  scrollWidth: number;
}

@Component({
  components: {
    PDFWebHeader,
    PDFWebPage,
    PDFSplitter
  }
})
export default class PDFWebViewport extends Vue {
  /**
   * VueJS object properties
   */
  @Prop({ required: true }) private pdfDocument!: any;
  @Prop({ required: true }) isDocumentSplittingEnabled!: boolean;
  @Prop({ required: true }) selectedDocumentId!: number;
  @Prop() invoiceIsValidationServices!: boolean;

  // use state management to avoid mutating Prop
  private readonly docViewerStore: DocViewerStore = getModule(
    DocViewerStore,
    this.$store
  );
  private get pdfScale() {
    return this.docViewerStore.getPdfScale;
  }
  private set pdfScale(scale: number) {
    this.docViewerStore.setPdfScale(scale);
  }

  /** Reactive Class Elements */
  private pdfPages: Array<any> = [];
  private pdfFocusedPage = 1;
  private zoomScaleLimits = {
    lower: 1.0,
    upper: 2.5
  };

  private viewportScrollData: ViewportScrollData = {
    scrollLeft: 0,
    scrollTop: 0,
    scrollHeight: 0,
    scrollWidth: 0
  };
  private isDocumentSplitterVisible = false;
  private handleSplitter() {
    this.isDocumentSplitterVisible = !this.isDocumentSplitterVisible;
  }

  /** Reference Components */
  @Ref("pdfHeader") private pdfHeader!: PDFWebHeader;
  @Ref("pdfViewportDiv") private pdfViewportDiv!: HTMLDivElement;
  @Ref("pdfPageComponents") private pdfPageComponents!: Array<PDFWebPage>;

  /**
   * Watchers
   */
  /** Will monitor the PDFDocument property for changes */
  @Watch("pdfDocument")
  onDocumentChange(oldVal: any, newVal: any) {
    this.pdfPages = [];
  }

  /** Will monitor the pdfScale property for changes */
  @Watch("pdfScale")
  onScaleChange(oldVal: any, newVal: any) {}

  @Watch("pdfLoadProgress")
  onLoadProgress(oldVal: any, newVal: any) {}

  /**
   * Lifecycle Hooks
   */
  created() {
    this.loadPages();
  }

  updated() {
    this.initScroll();
    this.renderVisiblePagesDebounced();
  }

  /** Emit Listener */
  private onChangeFocusPage(page: string) {
    if (page === "") {
      this.scrollToPage(1);
      return;
    }
    let n = Number(page);
    if (n < 1) {
      n = 1;
    } else if (n > this.pdfDocument.numPages + 1) {
      n = this.pdfDocument.numPages;
    }
    this.scrollToPage(n);
  }

  /**
   * Class Methods
   */
  private async loadPages() {
    const promises: any = range(1, this.pdfDocument.numPages + 1).map(number =>
      this.pdfDocument.getPage(number)
    );
    this.pdfPages = await Promise.all(promises);
  }

  /** Viewport Specific Methods */
  private initScroll() {
    this.viewportScrollData = {
      scrollLeft: 0,
      scrollTop: 0,
      scrollHeight: this.pdfViewportDiv.clientHeight,
      scrollWidth: this.pdfViewportDiv.clientWidth
    };
  }

  private onScroll(event: any) {
    this.viewportScrollData = {
      scrollLeft: event.target.scrollLeft,
      scrollTop: event.target.scrollTop,
      scrollHeight: event.target.scrollHeight,
      scrollWidth: event.target.scrollWidth
    };
    this.onScrollDebounce(event);
    this.onScrollThrottle(event);
  }

  /** We debounce the onScroll event to update once scrolling has stopped for 1/5th of a second.*/
  private onScrollDebounce = _.debounce((event: any) => {}, 200);

  /** We throttle the onScroll event, this function is called 5 times a second at most.*/
  private onScrollThrottle = _.throttle((event: any) => {
    this.updateFocusedPage();
    this.renderVisiblePages();
  }, 200);

  private onZoomIn() {
    if (this.pdfScale >= this.zoomScaleLimits.upper) {
      return;
    }
    this.pdfScale = Math.round((Number(this.pdfScale) + 0.1) * 10) / 10;
    // The focus page can change if zoomed out enough
    this.updateFocusedPage();
  }

  private onZoomOut() {
    if (this.pdfScale <= this.zoomScaleLimits.lower) {
      return;
    }
    this.pdfScale = Math.round((Number(this.pdfScale) - 0.1) * 10) / 10;
    this.updateFocusedPage();
  }

  private onFitToWidth() {
    if (
      this.pdfFocusedPage > 0 &&
      this.pdfFocusedPage <= this.pdfPages.length + 1
    ) {
      const pageViewport = this.pdfPages[this.pdfFocusedPage - 1].getViewport({
        scale: 1.0
      });
      const scale =
        Math.round((this.viewportWidth() / pageViewport.width - 0.1) * 10) / 10;
      if (scale != this.pdfScale) {
        this.pdfScale = scale;
      }
    }
  }

  private onFitToHeight() {
    if (
      this.pdfFocusedPage > 0 &&
      this.pdfFocusedPage <= this.pdfPages.length + 1
    ) {
      const pageViewport = this.pdfPages[this.pdfFocusedPage - 1].getViewport({
        scale: 1.0
      });
      const scale =
        Math.round((this.viewportHeight() / pageViewport.height) * 10) / 10;
      if (scale != this.pdfScale) {
        this.pdfScale = scale;
        this.updateFocusedPage();
      }
    }
  }

  private async onDownload() {
    const data = await this.pdfDocument.getData();
    const blob = new Blob([data], { type: "application/pdf" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${this.selectedDocumentId}`;
    link.click();
    URL.revokeObjectURL(link.href);
  }

  private isPageInViewport(element: any) {
    const pageTop = element.$el.offsetTop;
    const pageBottom = element.$el.offsetTop + element.$el.offsetHeight;

    if (
      (pageTop >= this.horizontalTop && pageTop <= this.horizontalBottom) ||
      (pageBottom >= this.horizontalTop &&
        pageBottom <= this.horizontalBottom) ||
      (pageTop <= this.horizontalTop && pageBottom >= this.horizontalBottom)
    ) {
      return true;
    }
    return false;
  }

  private debouncedRender = _.debounce(
    (visiblePages: PDFWebPage[]) => {
      visiblePages.forEach(async (element: PDFWebPage) => {
        await element.renderPage();
      });
    },
    500,
    { leading: false, trailing: true, maxWait: 5000 }
  );

  private renderVisiblePagesDebounced() {
    const visiblePages = this.pdfPageComponents.filter((element: any) => {
      return this.isPageInViewport(element);
    });
    visiblePages.forEach((element: PDFWebPage) => {
      element.updatePageCanvasDimensions();
    });
    this.debouncedRender(visiblePages);
  }

  private renderVisiblePages() {
    const visiblePages = this.pdfPageComponents.filter((element: any) => {
      return this.isPageInViewport(element);
    });
    visiblePages.forEach((element: PDFWebPage) => {
      element.updatePageCanvasDimensions();
    });
    visiblePages.forEach(async (element: PDFWebPage) => {
      element.renderPage();
    });
  }

  private updateFocusedPage() {
    if (this.pdfPageComponents === undefined) {
      return;
    }
    const pageCenter = this.horizontalCenter;

    this.pdfPageComponents.forEach((element: any) => {
      const pageTop = element.$el.offsetTop;
      const pageBottom = element.$el.offsetTop + element.$el.offsetHeight;

      if (pageCenter > pageTop && pageCenter < pageBottom) {
        this.pdfFocusedPage = element.pageNumber + 1;
      }
    });
  }

  private scrollToPage(n: number) {
    this.pdfHeader.setFocusedPage(n);
    this.pdfPageComponents[n - 1].renderPage();
    this.pdfViewportDiv.scrollTop = (this.pdfPageComponents[
      n - 1
    ] as any).$el.offsetTop;
  }

  /**
   * Computed Properties
   */
  viewportWidth() {
    return this.pdfViewportDiv?.offsetWidth ?? 0;
  }

  viewportHeight() {
    return this.pdfViewportDiv?.offsetHeight ?? 0;
  }

  /**
   * Horizontal boundary
   */
  get horizontalTop() {
    return this.viewportScrollData.scrollTop;
  }

  get horizontalCenter() {
    return (
      this.viewportScrollData.scrollTop +
      Math.floor(
        (this.pdfViewportDiv.clientHeight - this.pdfViewportDiv.clientTop) / 2
      )
    );
  }

  get horizontalBottom() {
    return this.viewportScrollData.scrollTop + this.pdfViewportDiv.clientHeight;
  }

  /**
   * Vertical Boundary
   */
  get verticalLeft() {
    return this.viewportScrollData.scrollLeft;
  }

  get verticalCenter() {
    return (
      this.viewportScrollData.scrollLeft +
      Math.floor(
        (this.pdfViewportDiv.clientWidth - this.pdfViewportDiv.clientLeft) / 2
      )
    );
  }

  get verticalRight() {
    return this.viewportScrollData.scrollLeft + this.pdfViewportDiv.clientWidth;
  }

  get focusedPage() {
    return this.pdfFocusedPage;
  }
}
