










import { Vue, Prop, Component, Watch } from "vue-property-decorator";

@Component
export default class PDFWebSpinner extends Vue {
  @Prop({ default: undefined }) private progress!: number;

  @Watch("progress")
  public onChange(newVal: number, oldVal: number) {}
}
