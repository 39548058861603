


























/* eslint-disable */
/** PDF.js is a javascript library with no typescript definitions */
const pdfjs = require("pdfjs-dist");
/* eslint-enable */

import { FieldCoordinates } from "@/models/document-entry/pdf-coordinates";
import { Vue, Prop, Component, Watch, Ref } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import DocViewerStore from "@/store/doc-viewer.store";

@Component
export default class PDFWebPage extends Vue {
  @Prop({ required: true }) private pdfPage!: any;

  // use state management to avoid mutating Prop
  private readonly docViewerStore: DocViewerStore = getModule(
    DocViewerStore,
    this.$store
  );
  private get pdfScale() {
    return this.docViewerStore.getPdfScale;
  }
  private get pdfCoordinates() {
    return this.docViewerStore.getPdfCoordinates;
  }
  private get focusedField() {
    return this.docViewerStore.getfocusedField;
  }

  /** Reactive Components */
  private pdfPageWidth = 0.0;
  private pdfPageHeight = 0.0;
  private focusedFieldCoordinates: FieldCoordinates | null = null;

  /** Non Reactive Components */
  private pdfViewport: any;
  private pdfPageRenderTask: any = null;
  private canvasContext!: CanvasRenderingContext2D | null;
  private highlightContext!: CanvasRenderingContext2D | null;
  private cachedCanvasContent!: ImageData | undefined;

  /** Reference components */
  @Ref("pageCanvas") pageCanvas!: HTMLCanvasElement;
  @Ref("highlightCanvas") highlightCanvas!: HTMLCanvasElement;

  /**
   * Watchers
   */
  @Watch("pdfPage")
  onPageChange(oldVal: any, newVal: any) {}

  @Watch("pdfScale")
  onScaleChange(oldVal: any, newVal: any) {
    /** If there is a render task cancel it and restart the render */
    if (this.pdfPageRenderTask) {
      this.pdfPageRenderTask.cancel();
    }
    this.pdfPageRenderTask = null;
    this.updatePageCanvasDimensions();
  }

  @Watch("pdfCoordinates")
  resetHighlightCanvas() {
    //clear all highlights
    if (this.highlightContext) {
      this.highlightContext.clearRect(
        0,
        0,
        this.pdfPageWidth,
        this.pdfPageHeight
      );
    }
    //apply grey highlight to all coordinates of recognized values
    this.pdfCoordinates.forEach(coordinates => {
      this.highlightField(coordinates, false);
    });
  }

  @Watch("focusedField")
  highlightFocusedField() {
    //change highlight of previously focused field to grey
    if (this.highlightContext && this.focusedFieldCoordinates) {
      this.highlightField(this.focusedFieldCoordinates, false);
    }
    this.setFocusedFieldCoordinates();
    //change highlight of currently focused field to green
    if (this.highlightContext && this.focusedFieldCoordinates) {
      this.highlightField(this.focusedFieldCoordinates, true);
    }
  }

  /**
   * Lifecycle Hooks
   */
  mounted() {
    this.initPageCanvas();
  }

  private setFocusedFieldCoordinates() {
    // check if the name of the field currently in focus matches one of the fields that has associated coordinates
    const currentFocusedFieldCoordinates = this.pdfCoordinates.filter(
      coord => coord.fieldName === this.focusedField + "Location"
    );
    if (currentFocusedFieldCoordinates.length > 0) {
      this.focusedFieldCoordinates = currentFocusedFieldCoordinates[0];
    } else {
      this.focusedFieldCoordinates = null;
    }
  }

  // area of pdf with given coordinates will be highlighted in green if focused or grey if not focused
  private highlightField(coordinates: FieldCoordinates, focused: boolean) {
    if (this.highlightContext) {
      if (coordinates.pageNumber === this.pageNumber) {
        //clear any highlight that was previously applied to this area
        this.highlightContext.clearRect(
          coordinates.xCoord * this.pdfPageWidth,
          coordinates.yCoord * this.pdfPageHeight,
          coordinates.width * this.pdfPageWidth,
          coordinates.height * this.pdfPageHeight
        );
        //apply green transparent rectangle if focused, or grey if not focused
        this.highlightContext.fillStyle = focused
          ? "rgba(0, 128, 32, .3)"
          : "rgba(128, 128, 128, .3)";
        this.highlightContext.fillRect(
          coordinates.xCoord * this.pdfPageWidth,
          coordinates.yCoord * this.pdfPageHeight,
          coordinates.width * this.pdfPageWidth,
          coordinates.height * this.pdfPageHeight
        );
      }
    }
  }

  /**
   * Class Methods
   */
  public initPageCanvas() {
    /** Get Viewport */
    this.pdfViewport = this.pdfPage.getViewport({ scale: this.pdfScale });
    /** Get Canvas */
    this.canvasContext = this.pageCanvas.getContext("2d");
    this.highlightContext = this.highlightCanvas.getContext("2d");
    /** Update Page dimensions */
    this.pdfPageWidth = this.pdfViewport.width;
    this.pdfPageHeight = this.pdfViewport.height;
  }

  public updatePageCanvasDimensions() {
    this.pdfViewport = this.pdfPage.getViewport({ scale: this.pdfScale });
    this.pdfPageWidth = this.pdfViewport.width;
    this.pdfPageHeight = this.pdfViewport.height;
  }

  /**
   * Called from parent component when the page is in viewport.
   */
  public async renderPage() {
    if (this.pdfPageRenderTask) {
      return;
    }

    try {
      this.pdfPageRenderTask = this.pdfPage.render({
        canvasContext: this.canvasContext,
        viewport: this.pdfViewport
      });
      /** Wait for the render task to resolve the promise */
      await this.pdfPageRenderTask.promise;

      if (this.highlightCanvas === undefined) return;
      /** Update the highlight layer page dimensions */
      this.highlightCanvas.style.setProperty(
        "top",
        String(this.pageCanvas.offsetTop) + "px"
      );
      this.highlightCanvas.style.setProperty(
        "left",
        String(this.pageCanvas.offsetLeft) + "px"
      );
      this.highlightCanvas.style.setProperty(
        "width",
        String(this.pdfViewport.width) + "px"
      );
      this.highlightCanvas.style.setProperty(
        "height",
        String(this.pdfViewport.height) + "px"
      );
      //apply grey highlight to all coordinates of recognized values
      this.pdfCoordinates.forEach(coordinates => {
        this.highlightField(coordinates, false);
      });
    } catch (error) {
      /** We handle the Rendering Canceled Exception, since we cause this exception */
      if ((error as any).name === "RenderingCancelledException") {
        return;
      }
      throw error;
    }
  }

  get pageNumber() {
    return this.pdfPage._pageIndex;
  }
}
