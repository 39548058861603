






























































































































import { Component, Prop, Watch, Emit } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import UserRolesMixin from "@/mixins/UserRoles.vue";
import DocViewerStore from "@/store/doc-viewer.store";
import { documentDetailsService } from "@/services/document-details.service";
import options from "@/shared/constants/toast-options";
import { arrayBufferToBlob } from "@/helpers/pdf-file-helper";

@Component({
  components: {}
})
export default class PDFWebHeader extends UserRolesMixin {
  /** Props */
  @Prop({ required: true }) private pdfPageCount!: number;
  @Prop({ required: true }) private pdfCurrentPage!: number;
  @Prop({ required: true }) private isDocumentSplittingEnabled!: boolean;

  /** Reactive properties */
  private proxyPdfCurrentPage = 1;
  private proxyPdfPageCount = 1;
  private isRegenerateImageButtonDisabled = false;

  private shouldShowRegenerateImageButton: boolean = this.$launchDarkly.variation(
    "show-edi-regenerate-image-button",
    false
  );

  // readonly store module
  private readonly docViewerStore: DocViewerStore = getModule(
    DocViewerStore,
    this.$store
  );

  // // computed properties
  private get authorizedToDownload() {
    // Ascend Admin and AP Specialist authorized to download
    return this.isAscendAdmin || this.isApSpecialist;
  }

  private get isEDIDocument() {
    return this.docViewerStore.getIsEDIDocument;
  }

  private openDocumentSplitter() {
    this.handleSplitter();
  }

  private get showSplitButton() {
    if (this.$route.path.includes("image")) {
      return false;
    }
    return true;
  }

  /** Watch for changes in the current page */
  @Watch("pdfCurrentPage")
  onCurrentPageChange(newVal: number, oldVal: number) {
    this.proxyPdfCurrentPage = newVal;
  }

  @Watch("pdfPageCount")
  onPageCountChange(newVal: number, oldVal: number) {
    this.proxyPdfPageCount = newVal;
  }

  /** Emits */
  @Emit("change-focus-page") changeFocusPage(n: number) {}

  @Emit("zoom-in") onZoomIn() {}

  @Emit("zoom-out") onZoomOut() {}

  @Emit("fitToWidth") fitToWidth() {}

  @Emit("fitToHeight") fitToHeight() {}

  @Emit("onDownload") onDownload() {}

  @Emit("handle-splitter") handleSplitter() {}

  /** VueJS Hooked Methods */
  mounted() {
    this.proxyPdfCurrentPage = this.pdfCurrentPage;
    this.proxyPdfPageCount = this.pdfPageCount;
  }

  public setFocusedPage(n: number) {
    this.proxyPdfCurrentPage = n;
  }

  /**
   * We only change the focused page once the enter key has been pressed.
   */
  private onEnterKey(event: Event) {
    this.changeFocusPage(this.proxyPdfCurrentPage);
  }

  async regenerateImageForEdiDocument() {
    if (!this.$route.path.includes("image")) {
      this.isRegenerateImageButtonDisabled = true;
      const regenerateResponse = await documentDetailsService.regenerateEDIDocumentImage(
        parseInt(this.$route.params.id)
      );
      if (regenerateResponse.status === 200) {
        const newImageData = regenerateResponse.data?.data;
        const newImage = arrayBufferToBlob(newImageData, "application/pdf");
        this.docViewerStore.setPdfDocument(newImage); // store new pdf
        this.docViewerStore.incrementPdfWebViewerKey(); // reload image component
      } else {
        const error: string = regenerateResponse?.data?.metadata?.message ?? "";
        let message = "Error occurred while regenerating EDI image";
        if (error.length) message += `: ${error}`;
        this.$toasted.show(message, options.ERROR_OPTIONS);
      }
      this.isRegenerateImageButtonDisabled = false;
    }
  }
}
