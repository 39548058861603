




























import { Component, Prop } from "vue-property-decorator";
import { documentDetailsService } from "@/services/document-details.service";
import PDFWebViewer from "@/components/doc-viewer/PDFWebViewer.vue";
import Catch from "@/shared/decorators/catch-errors";
import UserRolesMixin from "@/mixins/UserRoles.vue";
import { arrayBufferToBlob } from "@/helpers/pdf-file-helper";

@Component({
  components: {
    "pdf-web-viewer": PDFWebViewer
  }
})
export default class DocumentImage extends UserRolesMixin {
  // props
  @Prop() invoiceId!: string;

  // reactive class properties

  private isFetchingImage = false;
  private selectedDocumentId: number = parseInt(this.$route.params.id);
  private selectedDocumentImage: string | undefined = undefined;
  private pdfWebViewerKey = 1; // incremented when image loads to reload doc-viewer

  // lifecycle methods
  async created() {
    //set title of tab to invoice ID
    window.document.title = `Invoice ID: ${this.invoiceId}`;

    // update doc-viewer to display document image
    await this.setInvoiceImage();
  }

  // methods
  @Catch((error: any, context: any) => {
    context.selectedDocumentImage = undefined; // hides doc-viewer
    context.isFetchingImage = false;
    return;
  })
  async setInvoiceImage() {
    this.isFetchingImage = true;
    // make API call for invoice image
    const documentImage = await documentDetailsService.loadDocumentImage(
      this.selectedDocumentId
    );
    this.selectedDocumentImage = arrayBufferToBlob(
      documentImage,
      "application/pdf"
    );
    // increment key associated with doc-viewer to reload component
    this.isFetchingImage = false;
    this.pdfWebViewerKey++;
  }
}
