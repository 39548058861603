import { app } from "@/main";

class DocumentSplitterService {
  public async splitDocument(invID: number, documents: string[]) {
    const response = await app.$ravenapi.post(
      `api/DocumentSplitter?invID=${invID}`,
      documents
    );
    return response;
  }
}

export const documentSplitterService = new DocumentSplitterService(); // singleton
