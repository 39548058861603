import { render, staticRenderFns } from "./PDFWebViewer.vue?vue&type=template&id=361ffc52&scoped=true&"
import script from "./PDFWebViewer.vue?vue&type=script&lang=ts&"
export * from "./PDFWebViewer.vue?vue&type=script&lang=ts&"
import style0 from "./PDFWebViewer.vue?vue&type=style&index=0&id=361ffc52&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "361ffc52",
  null
  
)

export default component.exports