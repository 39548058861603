







































import UserRolesMixin from "@/mixins/UserRoles.vue";
import { Component, Emit, Prop, Watch } from "vue-property-decorator";
import { PDFDocument } from "@/models/document-entry/pdf-page";

@Component
export default class PDFDocumentWrapper extends UserRolesMixin {
  @Prop() documentWrapperID!: number;
  @Prop() document!: PDFDocument;
  @Prop({ default: false }) areSelectedPagesBeingDragged!: boolean;

  // PRIVATE METHODS
  //used for detect when a draggable object is dragged
  private handleDragStart(domEvent: any) {
    const draggingElement = domEvent.target;
    draggingElement.classList.add("dragging-page");
    this.emitDraggingPageKey(domEvent.target.id);
  }

  private handleDragEnd(domEvent: any) {
    const draggingElement = domEvent.target;
    draggingElement.classList.remove("dragging-page");
  }

  //used to detect if a draggable object is dropped in a drop zone
  private handleDrop(domEvent: any) {
    domEvent.preventDefault();
    const pageIDs = domEvent.target.id.split(" ");
    this.emitPageDroppedInDocument({
      docKey: this.document.key,
      siblingPDFPageKey: pageIDs[1],
      position: pageIDs[0]
    });
  }

  private loadCanvas() {
    this.document.pages.forEach((page, index) => {
      const pageContainer = document.getElementById(`page-${page.key}`);
      page.canvas.setAttribute("id", `canvas-${page.key}`);
      page.selected
        ? page.canvas.classList.add("selected-page")
        : page.canvas.classList.remove("selected-page");
      pageContainer?.appendChild(page["canvas"]);
    });
  }

  private loadEventlisternersToDropzones() {
    const documentWrapperDropzone = document.getElementById(
      `document-wrapper-dropzone-${this.document.key}`
    );
    if (documentWrapperDropzone) {
      documentWrapperDropzone.addEventListener("drop", this.handleDrop);
    }
  }

  private loadEventlistenersToPages() {
    const pages = document.querySelectorAll(".pdf-page");
    pages.forEach(page => {
      page.addEventListener("dragstart", this.handleDragStart);
      page.addEventListener("dragend", this.handleDragEnd);
    });
  }

  // EMIT METHODS
  @Emit("emit-dropped-in-document") emitPageDroppedInDocument(value: {
    docKey: number;
    siblingPDFPageKey: number;
    position: string;
  }) {
    return value;
  }
  @Emit("emit-dragging-page-key") emitDraggingPageKey(pageKey: number): number {
    return pageKey;
  }

  @Emit("emit-clicked-page-key") emitClickedPageKey(pageKey: number) {
    return pageKey;
  }

  //WATCHERS
  @Watch("areSelectedPagesBeingDragged")
  async addDraggingPageStyleToDraggedPages() {
    const removeSelectedPageClass = (
      draggedHTMLElements: HTMLCollectionOf<Element>
    ) => {
      for (let i = 0; i < draggedHTMLElements.length; i++) {
        draggedHTMLElements[i].classList.remove("selected-page");
      }
    };
    let draggedHTMLElements = document.getElementsByClassName("selected-page");
    const parentDivs: any[] = [];
    //const generate parents array first.
    for (let i = 0; i < draggedHTMLElements.length; i++) {
      const parent = draggedHTMLElements[i].closest(".pdf-page");
      parent?.classList.add("dragging-page");
    }
    //Removes the sborder solid for selected pages if they are being dragged
    removeSelectedPageClass(draggedHTMLElements);
    //This extra get and remove class is needed. If not is not working as expected
    draggedHTMLElements = document.getElementsByClassName("selected-page");
    removeSelectedPageClass(draggedHTMLElements);
  }

  // LIFECYCLE METHODS
  mounted() {
    //insert thumbnails in div
    this.loadCanvas();
    //add DnD listeners to pages-wrapper, is a drop zone
    this.loadEventlisternersToDropzones();
    //add DnD listeners to page, is a draggable element
    this.loadEventlistenersToPages();
  }
}
