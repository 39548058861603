























/**
 * Vue Component Imports
 */
import { Component, Prop } from "vue-property-decorator";

/**
 * JavaScript imports
 */

/** PDF.js is a javascript library with no typescript definitions */
const pdfjs = require("pdfjs-dist");
const pdfjsUtil = require("pdfjs-dist/web/pdf_viewer");

/* eslint-disable */
/** Load the PDF.js stylesheet */
require("pdfjs-dist/web/pdf_viewer.css");

/** Load the worker file from the library, we do not need to store the loaded data in a class */
require("pdfjs-dist/build/pdf.worker.entry");

/* eslint-enable */

/**
 * TypeScript imports
 */
import range from "lodash/range";
import { getModule } from "vuex-module-decorators";
import DocViewerStore from "@/store/doc-viewer.store";

/**
 * Additional Components
 */
import PDFWebSpinner from "./PDFWebSpinner.vue";
import PDFWebViewport from "./PDFWebViewport.vue";
import UserRolesMixin from "@/mixins/UserRoles.vue";

@Component({
  components: {
    PDFWebViewport,
    PDFWebSpinner
  }
})
export default class PDFWebViewer extends UserRolesMixin {
  @Prop({ default: undefined }) private url!: string | undefined;
  @Prop({ default: undefined }) private base64!: string | undefined;
  @Prop({ default: false }) isDocumentReadOnly!: boolean;
  @Prop({ required: true }) selectedDocumentId!: number;
  @Prop() invoiceIsValidationServices!: boolean;

  // use state management to avoid mutating Prop
  private readonly docViewerStore: DocViewerStore = getModule(
    DocViewerStore,
    this.$store
  );
  private get pdfScale() {
    return this.docViewerStore.getPdfScale;
  }
  private set pdfScale(scale: number) {
    this.docViewerStore.setPdfScale(scale);
  }

  /** Non Reactive Components */
  private pdfLoadingTask!: any;
  private pdfSourceData!: object;
  private pdfPage!: object;

  /** Reactive Components */
  private pdfLoadStatus: "Loading" | "Loaded" | "Failed" = "Loading";
  private pdfDocument: any = null;
  private pdfLoadProgress = 0.0;

  /**
   * LifeCycle Hooks
   * */
  created() {
    this.validateProps();
    this.validatePropData();
  }

  mounted() {
    this.loadDocument();
  }

  private get isDocumentSplittingEnabled() {
    return (
      !this.isDocumentReadOnly &&
      ((!this.docViewerStore.getHasEmailBody &&
        this.pdfDocument.numPages > 1) ||
        (this.docViewerStore.getHasEmailBody &&
          this.pdfDocument.numPages > 2)) &&
      this.hasEnterpriseProductSubscription
    );
  }

  /**
   * Class Methods
   */

  private validateProps() {
    if (this.base64 !== undefined && this.url !== undefined) {
      throw new Error(
        PDFWebViewer.name + " : Only use the url or base64 property, not both."
      );
    }
    if (this.base64 === undefined && this.url === undefined) {
      throw new Error(
        PDFWebViewer.name +
          " : Use the url or base64 property, to set document."
      );
    }
  }

  private validatePropData() {
    if (this.base64) {
      let binaryData;
      try {
        binaryData = atob(this.base64);
      } catch (error) {
        this.$destroy();
        throw new Error(
          PDFWebViewer.name + " : atob(), invalid base64 data encoding."
        );
      }
      this.pdfSourceData = { base64: binaryData };
    }
    if (this.url) {
      this.pdfSourceData = { url: this.url };
    }
  }

  private async loadDocument() {
    if (this.pdfSourceData === undefined) {
      throw new Error(PDFWebViewer.name + " : PDF source data is not set");
    }
    try {
      this.pdfLoadingTask = pdfjs.getDocument(this.pdfSourceData);
      /** Create Loading Hooks */
      this.pdfLoadingTask.onProgress = this.onLoadProgress;
      /** Start loading document, resolve promise */
      this.pdfDocument = await this.pdfLoadingTask.promise;
      this.pdfLoadStatus = "Loaded";
    } catch (error) {
      this.pdfLoadStatus = "Failed";
      throw Error(PDFWebViewer.name + " : " + (error as any)?.name);
    }
  }

  private onLoadProgress(val: { loaded: number; total: number }) {
    if (val) {
      this.pdfLoadProgress = val.loaded / val.total;
    }
  }
}
